const boatImages = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2Fboat%201.jpg?alt=media&token=73e7fe3b-d2ac-4a6a-9fd4-b7da62c8d03c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2Fboat%201.jpg?alt=media&token=73e7fe3b-d2ac-4a6a-9fd4-b7da62c8d03c",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2F2022-10-07%2017.08.15.jpg?alt=media&token=9e36354d-81a0-4d9a-9f7e-6341d70cc65c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2F2022-10-07%2017.08.15.jpg?alt=media&token=9e36354d-81a0-4d9a-9f7e-6341d70cc65c",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205.jpg?alt=media&token=bdb66c78-ddc3-4381-9ed9-8a674aba78e5",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205.jpg?alt=media&token=bdb66c78-ddc3-4381-9ed9-8a674aba78e5",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205-2.jpg?alt=media&token=690c1ae4-a10d-4f31-a1a0-472bf4f6a17d",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205-2.jpg?alt=media&token=690c1ae4-a10d-4f31-a1a0-472bf4f6a17d",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205-1.jpg?alt=media&token=343e8b16-ef10-4c76-9a20-5397d22c420a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%205-1.jpg?alt=media&token=343e8b16-ef10-4c76-9a20-5397d22c420a",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204.jpg?alt=media&token=85bce7d1-5742-430d-9582-d6f2d60a88a2",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204.jpg?alt=media&token=85bce7d1-5742-430d-9582-d6f2d60a88a2",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204-2.jpg?alt=media&token=685938fe-c350-4c89-a644-631570b99759",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204-2.jpg?alt=media&token=685938fe-c350-4c89-a644-631570b99759",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204-1.jpg?alt=media&token=2c70e868-b4d8-4277-8498-26e744f17940",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%204-1.jpg?alt=media&token=2c70e868-b4d8-4277-8498-26e744f17940",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203.jpg?alt=media&token=62c1ca59-64e0-4d0e-99e3-a41f90764cf1",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203.jpg?alt=media&token=62c1ca59-64e0-4d0e-99e3-a41f90764cf1",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203-2.jpg?alt=media&token=6697074c-3355-4273-ac23-cec6a6a3bb13",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203-2.jpg?alt=media&token=6697074c-3355-4273-ac23-cec6a6a3bb13",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203-1.jpg?alt=media&token=15a9ad31-1671-47b0-982d-8f07326c351c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%203-1.jpg?alt=media&token=15a9ad31-1671-47b0-982d-8f07326c351c",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%202.jpg?alt=media&token=71670a2f-b010-4ec7-8c29-5a62930f71dc",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgaleria%2FMesa%20de%20trabajo%201%20copia%202.jpg?alt=media&token=71670a2f-b010-4ec7-8c29-5a62930f71dc",
  },
];

export { boatImages };
