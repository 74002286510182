const carsImages = [
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(1).jpg?alt=media&token=b0bcf550-cc63-4aad-b51b-60528a51333a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(1).jpg?alt=media&token=b0bcf550-cc63-4aad-b51b-60528a51333a",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(2).jpg?alt=media&token=3981c91b-ead1-4310-a934-f06c89fe96b4",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(2).jpg?alt=media&token=3981c91b-ead1-4310-a934-f06c89fe96b4",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(3).jpg?alt=media&token=7a420fc0-c6dd-4715-9ce7-90bac4174927",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(3).jpg?alt=media&token=7a420fc0-c6dd-4715-9ce7-90bac4174927",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(4).jpg?alt=media&token=73704532-8b33-40bf-887d-7a7bee9bde20",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(4).jpg?alt=media&token=73704532-8b33-40bf-887d-7a7bee9bde20",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(5).jpg?alt=media&token=38ff064e-e33f-42e2-8e4d-377341fbfc90",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcorvette1%20(5).jpg?alt=media&token=38ff064e-e33f-42e2-8e4d-377341fbfc90",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%202.jpg?alt=media&token=f6c6d16b-2641-4b2c-b6f5-cdc7882fff85",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%202.jpg?alt=media&token=f6c6d16b-2641-4b2c-b6f5-cdc7882fff85",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%203.jpg?alt=media&token=1546c251-e57f-47fc-895a-243d07639fa9",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%203.jpg?alt=media&token=1546c251-e57f-47fc-895a-243d07639fa9",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%204.jpg?alt=media&token=467c1022-1a39-4e67-bf1b-54d418220ea7",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia%204.jpg?alt=media&token=467c1022-1a39-4e67-bf1b-54d418220ea7",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia.jpg?alt=media&token=57856f0f-94c8-448a-a823-d05ff3cf502a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro%20copia.jpg?alt=media&token=57856f0f-94c8-448a-a823-d05ff3cf502a",
  },

  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%2010.jpg?alt=media&token=59b4c70d-8ce8-4687-a794-07f1e0fc216e",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%2010.jpg?alt=media&token=59b4c70d-8ce8-4687-a794-07f1e0fc216e",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%2011.jpg?alt=media&token=725707e1-6f83-4f3f-b66b-d1d42289ee31",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%2011.jpg?alt=media&token=725707e1-6f83-4f3f-b66b-d1d42289ee31",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203-1.jpg?alt=media&token=871b65dc-8cbf-4845-8adf-ab877ae08c38",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203-1.jpg?alt=media&token=871b65dc-8cbf-4845-8adf-ab877ae08c38",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203-2.jpg?alt=media&token=eacf7260-c45a-4876-8ea3-871c0e745e36",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203-2.jpg?alt=media&token=eacf7260-c45a-4876-8ea3-871c0e745e36",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203.jpg?alt=media&token=1610e7ae-acba-4473-af4c-579d56230bfd",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%203.jpg?alt=media&token=1610e7ae-acba-4473-af4c-579d56230bfd",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204-1.jpg?alt=media&token=be759d99-a4c8-40cf-87bc-8a8114ef8b64",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204-1.jpg?alt=media&token=be759d99-a4c8-40cf-87bc-8a8114ef8b64",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204-2.jpg?alt=media&token=fa0ce447-d0ba-4a54-9011-1d2a04b6d412",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204-2.jpg?alt=media&token=fa0ce447-d0ba-4a54-9011-1d2a04b6d412",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204.jpg?alt=media&token=7bf838c4-96c3-4b00-927d-9ceb42609a4f",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%204.jpg?alt=media&token=7bf838c4-96c3-4b00-927d-9ceb42609a4f",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%205-1.jpg?alt=media&token=63065d9a-8998-4d9e-831d-ef369d240e99",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%205-1.jpg?alt=media&token=63065d9a-8998-4d9e-831d-ef369d240e99",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%205.jpg?alt=media&token=614c99cc-2a49-4aad-98da-5da5dc366d19",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%205.jpg?alt=media&token=614c99cc-2a49-4aad-98da-5da5dc366d19",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206-1.jpg?alt=media&token=9103646e-8550-4011-b413-43b5c5cc28ec",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206-1.jpg?alt=media&token=9103646e-8550-4011-b413-43b5c5cc28ec",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206-2.jpg?alt=media&token=7a1b1af5-93d8-4d14-8a21-88b08d8ed504",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206-2.jpg?alt=media&token=7a1b1af5-93d8-4d14-8a21-88b08d8ed504",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206.jpg?alt=media&token=3ba09032-7016-4787-b92d-37dd8fae31c3",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%206.jpg?alt=media&token=3ba09032-7016-4787-b92d-37dd8fae31c3",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%208.jpg?alt=media&token=660c3ce5-5737-466a-a80d-0a7b372caf81",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%208.jpg?alt=media&token=660c3ce5-5737-466a-a80d-0a7b372caf81",
  },
  {
    original:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%209.jpg?alt=media&token=78c4c48b-6b8c-45f0-b23a-8561ffbbca84",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fgaleria%2Fcarro1%20copia%209.jpg?alt=media&token=78c4c48b-6b8c-45f0-b23a-8561ffbbca84",
  },
];

export {carsImages}